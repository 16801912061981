import { testMyRentalsShallowMount } from "@/tests/unit/setup";
import { myRentalsShallowMount } from "@/tests/unit/setup";
import DocumentsPageContent from "@/components/protected/DocumentsPageContent.vue";
import { LocalStorageKeys } from "@/enums/Storage";
import Storage from "@/plugins/storage";

const component = () => {
  return myRentalsShallowMount(DocumentsPageContent);
};

beforeEach(() => {
  jest.restoreAllMocks();
});

const mockedUrl = "url";

describe("DocumentsPageContent", () => {
  testMyRentalsShallowMount(DocumentsPageContent);

  describe("Methods", () => {
    describe("goToDocumentView", () => {
      it("should call set local storage", () => {
        // given
        spyOn(Storage, "setLocalStorageItem");
        const wrapper = component();
        // when
        component();
        wrapper.vm.goToDocumentView(mockedUrl);
        // then
        expect(Storage.setLocalStorageItem).toHaveBeenCalledWith(
          LocalStorageKeys.IFRAME_URL,
          mockedUrl
        );
      });
      it("should call window open", () => {
        // given
        const wrapper = component();
        spyOn(window, "open");
        // when
        component();
        wrapper.vm.goToDocumentView(mockedUrl);
        // then
        expect(window.open).toHaveBeenCalledTimes(1);
      });
    });
  });
});
